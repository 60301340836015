import React, {FC, useEffect, useState} from "react";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {apiImageUrl, megaUrl} from "../../helper";
import Head from "next/head";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";

const HotelNavLinks = dynamic(() => import("./Hotel_NavLinks"))
const HotelNavPanel = dynamic(() => import("./Hotel_NavPanel"))
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {PropsConsumer} from "../../context";
import {setWindowSize} from "../../store/general";

interface IHeaderProps {
    layout?: string
}

const HotelHeader: FC<IHeaderProps> = ({layout = "default"}) => {
    const dispatch = useDispatch();
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const [isMobile, setIsMobile] = useState(false);
    let windowSize = useSelector((state: IState) => state.general.windowSize)

    const loadDefaultLogo = (e: any) => {
        e.target.src = `/stores-logo.svg?v=${cacheVersion}`;
    }
    /*FIXME make isolated file for all schema files*/
    const schemaSiteHeader = {
        "@context": `https://schema.org/`,
        "@type": "ItemList",
        "name": "Site Header Items",
        "description": "contact, change currency and language",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                name: "Telephone"
            },
            {
                "@type": "ListItem",
                "position": 2,
                name: "Currency"
            },
            {
                "@type": "ListItem",
                position: 3,
                name: "Language"
            },
        ],
        url: `${megaUrl}/`,
    }

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 768)
    }, [windowSize])

    return (
        <>
            <Head>
                <script type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: JSON.stringify(schemaSiteHeader)}}
                />
            </Head>
            <div className="site-header position-sticky">
                <div className="site-header__middle ">
                    <div className="container">

                        <div className="site-header__nav-panel">
                            <HotelNavPanel layout={layout}/>
                        </div>
                    </div>
                </div>
                <div className="site-header__bottom">
                    <div className="container">
                        <PropsConsumer>
                            {
                                props => (
                                    <HotelNavLinks pageProps={props} layout={layout}/>
                                )
                            }
                        </PropsConsumer>
                    </div>
                </div>
                {/*<div className="site-header__nav-panel">*/}
                {/*    <HotelNavPanel layout={layout}/>*/}
                {/*</div>*/}
            </div>
        </>
    );
}

export default React.memo(HotelHeader);
